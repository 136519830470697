import * as React from "react"
import styled, { keyframes } from "styled-components"
import Profile from "./profile"
import Gallery from "./gallery"
import Exhibition from "./exhibition"
import Contact from "./contact"

const anime = keyframes`
  0% {
    max-height:1px;
    width: 0vw;
  }
  20% {
    max-height: 1px;
    width: 96vw;
  }
  100% {
    max-height: 2000px;
  }
`
const childAnime = keyframes`
  0%{
    opacity: 0;
  }
  20%{
    opacity: 0;
  }
  30%{
    opacity: 0.5;
  }
  50%{
    opacity: 0.9;
  }
  100%{
    opacity: 1;
  }
`

const Wrap = styled.div`
  background-color: rgba(0, 0, 0, 0.6);
  color: #ccc;
  margin: 4vh 0 12vh 4vw;
  /* 開いた時にアニメーション */
  animation: ${anime} 5s ease;
`
const Child = styled.div`
  padding: 2vw;
  &::after {
    content: "";
    display: block;
    width: 1px;
    height: 4vw;
  }
  /* 初回Content表示用アニメーション*/
  animation: ${childAnime} 5s ease;
`

interface Props {
  select: string
  isOpen: boolean
}
export default class Content extends React.Component<Props> {
  render() {
    let element: JSX.Element
    switch (this.props.select) {
      case "profile":
        element = <Profile />
        break
      case "gallery":
        element = <Gallery />
        break
      case "exhibition":
        element = <Exhibition />
        break
      case "contact":
        element = <Contact />
        break
      default:
        element = <></>
    }

    return (
      <Wrap>
        <Child>
          <FadeAnime>{element}</FadeAnime>
        </Child>
      </Wrap>
    )
  }
}

// CSSアニメーション
const wrapAnime = keyframes`
  0%{
    opacity:0;
  }
  100%{
    opacity:1;
  }
`
// アニメーション用CSS
const AnimeWrap = styled.div`
  &.is-animate {
    animation: ${wrapAnime} 1.8s ease;
  }
`
interface FadeProps {
  children: any
}
export class FadeAnime extends React.Component<FadeProps> {
  render() {
    // フェードインでアニメーションさせるためのクラスを生成
    let e = document.querySelector("#fade-animation")
    if (e) {
      e.classList.remove("is-animate")
      // reflow
      void e.clientWidth
      e.classList.add("is-animate")
    }

    return <AnimeWrap id="fade-animation">{this.props.children}</AnimeWrap>
  }
}
