import * as React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Navigation from "../components/navigation"
import Content from "../components/content"
import { GlobalStyle } from "../global/styles"
import MainImage from "../components/main-image"
import Footer from "../components/footer"

const IndexPage = () => {
  // 表示ナビゲーション状態管理
  const [isContentOpen, setContentOpen] = React.useState(false)
  const [contentSelect, setContentSelect] = React.useState("")

  let navFontSize: string
  let fontSize: string
  let windowWidth: number
  let windowHeight: number

  if (typeof window !== "undefined") {
    windowWidth = window.innerWidth
    windowHeight = window.innerHeight
    if (windowWidth * 0.032 >= 18 && windowHeight * 0.032 >= 18) {
      fontSize = "18px;"
    } else {
      fontSize = "3.2vmin;"
    }
    if (windowWidth * 0.038 >= 40 && windowHeight * 0.038 >= 40) {
      navFontSize = "40px;"
    } else {
      navFontSize = "3.8vmin;"
    }
  }

  const selectContent = (select: string) => {
    // メニューが開いており、かつ同じメニューを選択した場合画面を閉じる
    if (isContentOpen) {
      if (contentSelect === select) {
        setContentOpen(false)
      }
    } else {
      setContentOpen(true)
    }
    // メニュー項目を選択する
    setContentSelect(select)
  }
  const closeContent = () => {
    setContentOpen(false)
    setContentSelect("")
  }

  return (
    <>
      <Layout>
        <GlobalStyle fontSize={fontSize} navFontSize={navFontSize} />
        <SEO />
        <Navigation select={selectContent} close={closeContent} />
        <MainImage />
        {isContentOpen && (
          <Content select={contentSelect} isOpen={isContentOpen} />
        )}
        <Footer />
      </Layout>
    </>
  )
}

export default IndexPage
