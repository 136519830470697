import * as React from "react"
import styled from "styled-components"
import Title from "./title"
import IntlContext from "./context"
import {
  profile_ja,
  profile_en,
  gallery_ja,
  gallery_en,
  exhibition_ja,
  exhibition_en,
  contact_ja,
  contact_en,
} from "./svg"

const Wrap = styled.nav`
  display: flex;
  justify-content: space-between;
  padding: 2vh 0 2vh 3vw;
`
const Nav = styled.div`
  color: ${(props: StyledProps) => (props.active ? "#c39747" : "#1a1a1a")};
  font-family: "ro-shino-std", serif;
  margin: 0 2.8vw;
  transition: 0.25s;
  width: 1em;
  &:hover {
    cursor: pointer;
  }
`
const FlexItem = styled.div`
  display: flex;
  white-space: nowrap;
`

interface Props {
  select: (select: string) => void
  close: () => void
}
interface State {
  active: boolean
  selected: string
}
interface StyledProps {
  active: boolean
}

interface TextContent {
  profile: string
  gallery: string
  exhibition: string
  contact: string
  profileSvg: JSX.Element
  gallerySvg: JSX.Element
  exhibitionSvg: JSX.Element
  contactSvg: JSX.Element
}
const ja = {
  profile: "人物紹介",
  gallery: "制作物",
  exhibition: "展示情報",
  contact: "お問合わせ",
  profileSvg: profile_ja,
  gallerySvg: gallery_ja,
  exhibitionSvg: exhibition_ja,
  contactSvg: contact_ja,
}
const en = {
  profile: "Profile",
  gallery: "Gallery",
  exhibition: "Exhibitions",
  contact: "Contact",
  profileSvg: profile_en,
  gallerySvg: gallery_en,
  exhibitionSvg: exhibition_en,
  contactSvg: contact_en,
}

export default class Navigation extends React.Component<Props> {
  public state: State = {
    active: false,
    selected: "",
  }
  private selectContent = (select: string) => {
    this.props.select(select)
    if (this.state.selected === select) {
      this.setState({ selected: "" })
    } else {
      this.setState({ selected: select })
    }
  }
  private closeContent = () => {
    this.props.close()
    this.setState({ selected: "" })
  }

  public render() {
    return (
      <IntlContext.Consumer>
        {({ language }) => {
          let text: TextContent
          switch (language) {
            case "ja":
              text = ja
              break
            case "en":
              text = en
              break
            default:
          }

          return (
            <Wrap id="main-nav">
              <div
                onClick={this.closeContent.bind(this)}
                style={{ height: "fit-content" }}
              >
                <Title />
              </div>
              <FlexItem>
                <Nav
                  onClick={this.selectContent.bind(this, "profile")}
                  active={this.state.selected === "profile"}
                >
                  {text.profileSvg}
                </Nav>
                <Nav
                  onClick={this.selectContent.bind(this, "gallery")}
                  active={this.state.selected === "gallery"}
                >
                  {text.gallerySvg}
                </Nav>
                <Nav
                  onClick={this.selectContent.bind(this, "exhibition")}
                  active={this.state.selected === "exhibition"}
                >
                  {text.exhibitionSvg}
                </Nav>
                <Nav
                  onClick={this.selectContent.bind(this, "contact")}
                  active={this.state.selected === "contact"}
                >
                  {text.contactSvg}
                </Nav>
              </FlexItem>
            </Wrap>
          )
        }}
      </IntlContext.Consumer>
    )
  }
}
