import * as React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import IntlContext from "./context"

const Wrap = styled.div``
const Content = styled.div`
  padding: 1vh 0;
`
const StyledH1 = styled.h1`
  border-bottom: solid 1px #c39747;
  padding: 1vh 0;
`
const TdYear = styled.td`
  font-weight: bold;
  padding-right: 1.2vw;
`

interface TextContent {
  name: string
  place: string
  college: string
  grad: string
  bio: string
}
const ja = {
  name: "作家　山本 有彩",
  place: "石川県在住",
  college: "金沢美術工芸大学 日本画専攻 卒業",
  grad: "金沢美術工芸大学院修士課程 絵画専攻 日本画コース 修了",
  bio: "主な経歴",
}
const en = {
  name: "Arisa Yamamoto  -Japanese Artist-",
  place: "Living in Ishikawa",
  college: "B.F.A, Kanazawa College of Art",
  grad: "M.F.A, Kanazawa College of Art",
  bio: "Biography",
}

const Profile = () => {
  return (
    <IntlContext.Consumer>
      {({ language }) => {
        let text: TextContent
        switch (language) {
          case "ja":
            text = ja
            break
          case "en":
            text = en
            break
          default:
        }

        return (
          <Wrap>
            <StyledH1>{text.name}</StyledH1>
            <Content>
              <p>{text.place}</p>
              <table>
                <tbody>
                  <tr>
                    <TdYear>2017</TdYear>
                    <td>{text.grad}</td>
                  </tr>
                </tbody>
                <tbody>
                  <tr>
                    <TdYear>2015</TdYear>
                    <td>{text.college}</td>
                  </tr>
                </tbody>
              </table>
            </Content>
            <StyledH1>{text.bio}</StyledH1>
            <Content>
              <Data lang={language} />
            </Content>
          </Wrap>
        )
      }}
    </IntlContext.Consumer>
  )
}
export default Profile

const Data = (props: { lang: string }) => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulBiography(sort: { fields: [year], order: DESC }) {
        edges {
          node {
            id
            year
            result {
              result
            }
            node_locale
          }
        }
      }
    }
  `)
  const bios: IProfile[] = data.allContentfulBiography.edges
  return (
    <table>
      {bios.map(({ node }) => {
        if (node.node_locale !== props.lang) {
          return false
        }
        const results = node.result.result.split(/[\n\r]/g)

        return (
          <tbody key={node.id}>
            {results.map((result: string, index: number) => {
              if (index === 0) {
                return (
                  <tr key={result}>
                    <TdYear>{node.year}</TdYear>
                    <td>{result}</td>
                  </tr>
                )
              } else {
                return (
                  <tr key={result}>
                    <td></td>
                    <td>{result}</td>
                  </tr>
                )
              }
            })}
          </tbody>
        )
      })}
    </table>
  )
}
interface IProfile {
  node: {
    id: string
    year: string
    result: {
      result: any
    }
    node_locale: string
  }
}
