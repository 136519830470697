import * as React from "react"
import styled from "styled-components"
import { FiFacebook, FiTwitter } from "react-icons/fi"
import IntlContext from "./context"

const StyledFooter = styled.footer`
  background-color: #1a1a1a;
  color: #ccc;
  height: 16vh;
  position: absolute;
  bottom: 0;
  width: 100%;
`
const SNSItems = styled.div`
  display: flex;
  font-size: 1.2em;
  height: 4vh;
  justify-content: center;
  margin: auto;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`
const SNSLink = styled.a`
  color: inherit;
  height: 1em;
  &:hover {
    cursor: pointer;
  }
`
const Copyright = styled.p`
  font-size: 0.8em;
  padding: 0 2vw;
  position: absolute;
  bottom: 0;
`
const Language = styled.a`
  text-decoration: underline;
  display: inline-block;
  font-size: 1em;
  padding: 1vh 2vw;
  position: absolute;
  right: 0;
  &:hover {
    cursor: pointer;
  }
`

export default class Footer extends React.Component {
  render() {
    const year: number = new Date().getFullYear()
    return (
      <IntlContext.Consumer>
        {({ language, changeLanguage }) => {
          let anotherLang = ""
          if (language === "ja") {
            anotherLang = "English"
          }
          if (language === "en") {
            anotherLang = "Japanese"
          }
          return (
            <StyledFooter>
              <Language onClick={changeLanguage}>{anotherLang}</Language>
              <SNSItems>
                <SNSLink
                  href="https://twitter.com/ymmtarsdoumo"
                  target="_blank"
                  rel="noopener"
                >
                  <FiTwitter
                    title="twitter"
                    style={{ fill: "#c39747", padding: "0 5vw" }}
                  />
                </SNSLink>
                <SNSLink
                  href="https://www.facebook.com/renzokukougeki"
                  target="_blank"
                  rel="noopener"
                >
                  <FiFacebook
                    title="facebook"
                    style={{ fill: "#c39747", padding: "0 5vw" }}
                  />
                </SNSLink>
              </SNSItems>
              <Copyright>©{year} Arisa Yamamoto All Rights Reserved</Copyright>
            </StyledFooter>
          )
        }}
      </IntlContext.Consumer>
    )
  }
}
