import * as React from "react"
import styled from "styled-components"
import IntlContext from "./context"

const Content = styled.div`
  padding: 1vh 0;
`
const StyledH1 = styled.h1`
  border-bottom: solid 1px #c39747;
  padding: 1vh 0;
`
const StyledInput = styled.input`
  background-color: #eee;
  border: solid 1px #1a1a1a;
  box-sizing: border-box;
  font-family: 游明朝, "Yu Mincho", YuMincho, "sans-serif";
  font-size: 1em;
  height: 1.5em;
  width: 100%;
  &:focus {
    border: solid 2px #c39747;
    outline: 0;
  }
`
const StyledTextarea = styled.textarea`
  background-color: #eee;
  border: solid 1px #1a1a1a;
  box-sizing: border-box;
  font-family: 游明朝, "Yu Mincho", YuMincho, "sans-serif";
  font-size: 1em;
  height: 30vh;
  resize: none;
  width: 100%;
  &:focus {
    border: solid 2px #c39747;
    outline: 0;
  }
`
const StyledButton = styled.button`
  background-color: #eee;
  border: solid 1px #1a1a1a;
  display: inline-block;
  font-family: "ro-nikkatsu-sei-kai-std", 游明朝, "Yu Mincho", YuMincho,
    "sans-serif";
  font-size: 0.9em;
  height: 3em;
  padding: 0.3em 1em;
  text-decoration: none;
  transition: 0.25s;
  width: 40%;
  &:hover {
    background: #c39747;
    color: #eee;
    cursor: pointer;
  }
`
const StyledEmail = styled.p`
  color: #ccc;
  text-decoration: underline;
  &:hover {
    cursor: text;
  }
`

interface TextContent {
  title: string
  message: string
  email: string
  fName: string
  fEmail: string
  fMessage: string
  fSubmit: string
}
const ja = {
  title: "お問合わせ",
  message:
    "展示のお誘いや展示のお問い合わせなどは以下のアドレス、もしくはフォームよりお願いいたします。",
  email: "連絡先メールアドレス:",
  fName: "御名前(必須)",
  fEmail: "メールアドレス(必須)",
  fMessage: "本文(必須)",
  fSubmit: "送信",
}
const en = {
  title: "CONTACT",
  message:
    "Please contact the following E-mail or form for invitations and inquiries about the exhibition.",
  email: "E-mail:",
  fName: "Your Name (required)",
  fEmail: "Your Email (required)",
  fMessage: "Your Message (required)",
  fSubmit: "Send",
}

export default class Contact extends React.Component {
  render() {
    return (
      <IntlContext.Consumer>
        {({ language }) => {
          let text: TextContent
          switch (language) {
            case "ja":
              text = ja
              break
            case "en":
              text = en
              break
            default:
          }

          return (
            <>
              <StyledH1>{text.title}</StyledH1>
              <Content>
                <p>{text.message}</p>
                <h3 style={{ paddingTop: "1vh" }}>{text.email}</h3>
                <StyledEmail>yamamotoarisa.knb@gmail.com</StyledEmail>
              </Content>
              <Content>
                <div
                  style={{ borderTop: "solid 1px #c39747", padding: "1vh 0" }}
                >
                  <Form text={text} />
                </div>
              </Content>
            </>
          )
        }}
      </IntlContext.Consumer>
    )
  }
}

interface FormProps {
  text: TextContent
}
class Form extends React.Component<FormProps> {
  render() {
    return (
      <form name="contact" method="POST" data-netlify="true">
        <input type="hidden" name="form-name" value="contact" />
        <div className="name">
          <p>{this.props.text.fName}</p>
          <StyledInput type="text" name="name" placeholder="Name" required />
        </div>
        <div className="address">
          <p>{this.props.text.fEmail}</p>
          <StyledInput
            type="email"
            name="email"
            placeholder="Email"
            pattern="[\w\d_-]+@[\w\d_-]+\.[\w\d._-]+"
            required
          />
        </div>
        <div className="message">
          <p>{this.props.text.fMessage}</p>
          <StyledTextarea name="value" placeholder="Message" required />
        </div>
        <div
          className="submit"
          style={{ textAlign: "center", marginTop: "3vh" }}
        >
          <StyledButton type="submit">{this.props.text.fSubmit}</StyledButton>
        </div>
      </form>
    )
  }
}
