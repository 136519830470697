import styled, { createGlobalStyle } from "styled-components"
import reset from "styled-reset"

interface Props {
  fontSize: string
  navFontSize: string
}
export const GlobalStyle = createGlobalStyle<Props>`
  ${reset}
  body{
    cursor: default;
    font-family: 'ro-nikkatsu-sei-kai-std', 游明朝, 'Yu Mincho', YuMincho, 'sans-serif';
    font-size: ${props => props.fontSize};
    line-height: 1.8em;
    margin: 0;
  }
  h1{
    color: #c39747;
    font-size: 1.8em;
    line-height: 1.3em;
  }
  h2{
    font-size: 1.5em;
    line-height: 1.3em;
  }
  h3{
    font-size: 1.1em;
    line-height: 1.3em;
  }
  footer{
    font-family: 游明朝,'Yu Mincho',YuMincho,'ro-nikkatsu-sei-kai-std', 'sans-serif';
  }
  #main-nav{
    font-size: ${props => props.navFontSize};
  }
  .ReactModalPortal{
    font-family: 游明朝,'Yu Mincho',YuMincho, 'sans-serif';
  }
`

export const WindowStyle = styled.div`
  font-family: "ro-nikkatsu-sei-kai-std", "serif";
  margin: 8vw;
  padding: 2vw;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`
export const CloseButton = styled.div`
  position: absolute;
  right: 2vw;
  z-index: 1;
  svg {
    height: 40px;
    width: 40px;
  }
`
