import * as React from "react"
import styled from "styled-components"
import { TitleLogo } from "./svg"

const Wrap = styled.div`
  margin-left: 2vw;
  width: 40vmin;
  &:hover {
    cursor: pointer;
  }
`

interface ITitle {
  data: {
    file: {
      childImageSharp: {
        fluid: any
      }
    }
  }
}

export default class Title extends React.Component {
  data: ITitle
  public render() {
    return (
      <Wrap>
        <TitleLogo />
      </Wrap>
    )
  }
}
