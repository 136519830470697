import * as React from "react"
import styled, { keyframes } from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import IntlContext from "./context"
import { FiChevronLeft, FiChevronRight, FiMoreHorizontal } from "react-icons/fi"
import Lightbox from "react-image-lightbox"
import { scroller } from "react-scroll"

const Content = styled.div`
  border-bottom: solid 1px #c39747;
  padding: 1vh 0;
`

interface TextContent {
  title: string
  gallery: string
  address: string
  period: string
  hours: string
}

const ja = {
  title: "展示情報",
  gallery: "会場",
  address: "住所",
  period: "会期",
  hours: "時間",
}
const en = {
  title: "EXHIBITION",
  gallery: "Venue",
  address: "Address",
  period: "Period",
  hours: "Hours",
}

const Exhibition = () => {
  return (
    <IntlContext.Consumer>
      {({ language }) => {
        let text: TextContent
        switch (language) {
          case "ja":
            text = ja
            break
          case "en":
            text = en
            break
          default:
        }

        return (
          <div>
            <Content>
              <h1>{text.title}</h1>
            </Content>
            <Data lang={language} text={text} />
          </div>
        )
      }}
    </IntlContext.Consumer>
  )
}
export default Exhibition

const InfoBlock = styled.div`
  border-bottom: solid 1px #c39747;
  padding: 2vh 0 4vh 0;
  word-break: break-word;
`
const StyledImg = styled(Img)`
  height: auto;
  margin: 1vh 2vw;
  div {
    height: 35vh !important;
    padding-bottom: 0 !important;
  }
  img {
    max-height: 35vh;
    object-fit: contain !important;
  }
`
const StyledH2 = styled.h2`
  font-size: 1.5em;
`
const StyledTable = styled.table`
  margin: 0.8vh 0;
`
const StyledTd = styled.td`
  padding: 0 3vw;
  white-space: nowrap;
`
const StyledDesc = styled.p`
  margin: 0.8vh 0 0 3vw;
`
const StyledUrl = styled.a`
  color: #ccc;
  &:visited {
    color: #ccc;
  }
  &:hover {
    cursor: pointer;
  }
`
const StyledNav = styled.nav`
  display: flex;
  font-size: 1.3em;
  font-weight: bold;
  line-height: 2.2em;
  justify-content: center;
  margin-top: 4vh;
`
const StyledNavEl = styled.a`
  background-color: ${(props: StyledProps) => (props.active ? "#1a1a1a" : "")};
  border: solid 1px #ccc;
  color: ${(props: StyledProps) => (props.active ? "#c39747" : "#ccc")};
  height: 2.2em;
  text-align: center;
  margin: 0 0.2em;
  transition: 0.25s;
  width: 2.2em;
  &:hover {
    background: #1a1a1a;
    color: #c39747;
    cursor: pointer;
  }
`
const StyledNavP = styled.p`
  color: #ccc;
  height: 2.2em;
  margin: 0 0.5em;
`

interface DataProps {
  lang: string
  text: TextContent
}
interface DataState {
  pageNo: number
}
interface StyledProps {
  active: boolean
}
const Data = ({ lang, text }: DataProps) => {
  const [pageNo, setPageNo] = React.useState(1)
  // ページの切り替え
  const changePage = (page: number) => {
    scroller.scrollTo("fade-animation", {})
    setPageNo(page)
  }
  // ページャーの生成
  const createPager = (pageLength: number) => {
    let pageList = []
    for (var i = 0; i <= pageLength + 1; i++) {
      // pageLength over 6 & ループは最初でも最後でもない
      if (pageLength >= 6 && !(i <= 1 || pageLength <= i)) {
        // 表示ページ数は前に0,後ろに2表示、その他は省略
        if (i <= pageNo - 1 || pageNo + 3 <= i) {
          // 途中のページは省略する
          if (i === pageNo - 1 || i === pageNo + 3) {
            const element = (
              <StyledNavP>
                <FiMoreHorizontal style={{ height: "2.2em" }} />
              </StyledNavP>
            )
            pageList.push(element)
            continue
          }
          // 最初のページと最後のページは表示する
          if (i !== 0 || i !== pageLength) {
            continue
          }
        }
      }
      switch (i) {
        case 0: // PrevButton
          if (pageNo !== 1) {
            const element = (
              <StyledNavEl
                key={i}
                onClick={() => changePage(pageNo - 1)}
                active={pageNo === i}
              >
                <FiChevronLeft style={{ height: "100%" }} />
              </StyledNavEl>
            )
            pageList.push(element)
          }
          break
        case pageLength + 1: // NextButton
          if (pageNo !== pageLength) {
            const element = (
              <StyledNavEl
                key={i}
                onClick={() => changePage(pageNo + 1)}
                active={pageNo === i}
              >
                <FiChevronRight style={{ height: "100%" }} />
              </StyledNavEl>
            )
            pageList.push(element)
          }
          break
        default:
          // PageNoButton
          const no = i
          const element = (
            <StyledNavEl
              key={i}
              onClick={() => changePage(no)}
              active={pageNo === i}
            >
              {i}
            </StyledNavEl>
          )
          pageList.push(element)
          break
      }
    }
    return (<StyledNav>{pageList}</StyledNav>)
  }
  const data = useStaticQuery(graphql`
    query {
      allContentfulExhibitions(sort: { fields: [dateStart], order: DESC }) {
        edges {
          node {
            id
            title
            gallery
            description
            dateStart(formatString: "YYYY/MM/DD")
            dateEnd(formatString: "YYYY/MM/DD")
            hours
            directMessage {
              fluid {
                ...GatsbyContentfulFluid_withWebp
              }
            }
            thumbnail {
              fluid {
                ...GatsbyContentfulFluid_withWebp
              }
            }
            address
            url
            node_locale
          }
        }
      }
    }
  `)
  const exhibitions: IExhibitions[] = data.allContentfulExhibitions.edges
  // 言語フィルター
  const filterExhibitions: IExhibitions[] = exhibitions.filter(({ node }) => {
    if (node.node_locale === lang) {
      return true
    } else {
      return false
    }
  })
  const pageLength = Math.ceil(filterExhibitions.length / 5)
  // メニュー
  const menu: JSX.Element = <div>{createPager(pageLength)}</div>
  return (
    <FadeAnime>
      {filterExhibitions.map(({ node }, index) => {
        if ((pageNo - 1) * 5 > index || index >= pageNo * 5) {
          return false
        }
        // DMImage
        let DMImage: JSX.Element
        if (node.directMessage) {
          DMImage = (
            <LightImage
              imgFluid={node.directMessage.fluid}
              title={node.title}
            />
          )
        }
        // WorkImage
        let Image: JSX.Element
        if (node.thumbnail) {
          Image = (
            <LightImage imgFluid={node.thumbnail.fluid} title={node.title} />
          )
        }
        let Images: JSX.Element
        if (node.thumbnail || node.directMessage) {
          Images = (
            <div style={{ display: "flex", maxHeight: "35vh" }}>
              {DMImage}
              {Image}
            </div>
          )
        }

        // 住所
        let Address: JSX.Element
        if (node.dateStart) {
          Address = (
            <tr>
              <StyledTd>{text.address}</StyledTd>
              <td>{node.address}</td>
            </tr>
          )
        }
        // 開催日時情報
        let Period: JSX.Element
        if (node.dateStart) {
          Period = (
            <tr>
              <StyledTd>{text.period}</StyledTd>
              <td>
                {node.dateStart}-{node.dateEnd}
              </td>
            </tr>
          )
        }
        // 開催日時情報
        let Hours: JSX.Element
        if (node.hours) {
          Hours = (
            <tr>
              <StyledTd>{text.hours}</StyledTd>
              <td>{node.hours}</td>
            </tr>
          )
        }

        // URL情報
        let URL: JSX.Element
        if (node.url) {
          URL = (
            <tr>
              <StyledTd>URL</StyledTd>
              <td>
                <StyledUrl href={node.url} target="_break" rel="noopener">
                  {node.url}
                </StyledUrl>
              </td>
            </tr>
          )
        }

        return (
          <InfoBlock key={node.id}>
            <div>
              <StyledH2>{node.title}</StyledH2>
              <StyledTable>
                <tbody>
                  <tr>
                    <StyledTd>{text.gallery}</StyledTd>
                    <td>{node.gallery}</td>
                  </tr>
                  {Address}
                  {Period}
                  {Hours}
                  {URL}
                </tbody>
              </StyledTable>
              <StyledDesc>{node.description}</StyledDesc>
            </div>
            {Images}
          </InfoBlock>
        )
      })}
      {menu}
    </FadeAnime>
  )
}

const StyledZoomImg = styled.div`
  transition: 0.3s;
  &:hover {
    opacity: 0.8;
    cursor: zoom-in;
  }
`
interface ImageProps {
  imgFluid: any
  title: string
}
interface ImageState {
  isOpen: boolean
}
// lightbox
class LightImage extends React.Component<ImageProps> {
  public state: ImageState = {
    isOpen: false,
  }
  render() {
    return (
      <div style={{ width: "100%", margin: "auto" }}>
        <StyledZoomImg onClick={() => this.setState({ isOpen: true })}>
          <StyledImg fluid={this.props.imgFluid} loading="auto" />
        </StyledZoomImg>
        {this.state.isOpen && (
          <Lightbox
            imageTitle={this.props.title}
            mainSrc={this.props.imgFluid.src}
            onCloseRequest={() => this.setState({ isOpen: false })}
          />
        )}
      </div>
    )
  }
}

interface IExhibitions {
  node: {
    id: string
    title: string
    gallery: string
    description: string
    dateStart: string
    dateEnd: string
    hours: string
    directMessage: any
    thumbnail: any
    address: string
    url: string
    node_locale: string
  }
}

// CSSアニメーション
const wrapAnime = keyframes`
  0%{
    opacity:0;
  }
  100%{
    opacity:1;
  }
`
// アニメーション用CSS
const AnimeWrap = styled.div`
  &.is-animate {
    animation: ${wrapAnime} 1.8s ease;
  }
`
interface FadeProps {
  children: any
}
export class FadeAnime extends React.Component<FadeProps> {
  render() {
    // フェードインでアニメーションさせるためのクラスを生成
    let e = document.querySelector("#fade-animation")
    if (e) {
      e.classList.remove("is-animate")
      // reflow
      void e.clientWidth
      e.classList.add("is-animate")
    }

    return <AnimeWrap id="fade-animation">{this.props.children}</AnimeWrap>
  }
}
