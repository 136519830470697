import * as React from "react"
import styled from "styled-components"
import Translate from "./translate"

interface Props {
  children: any
}

const bgImage = require("../images/background.png")
const Wrap = styled.main`
  box-sizing: border-box;
  min-height: 100vh;
  padding-bottom: 14vmin;
  position: relative;
  background-image: url(${bgImage});
  background-position: top center;
  background-repeat: repeat;
  background-size: 100px 100px;
`

export default class Layout extends React.Component<Props> {
  data: ILayout

  public render() {
    return (
      <Wrap>
        <Translate>{this.props.children}</Translate>
      </Wrap>
    )
  }
}

interface ILayout {
  file: {
    childImageSharp: {
      fluid: any
    }
  }
}
