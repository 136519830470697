import * as React from "react"
import IntlContext from "./context"

interface Props {
  children: any
}
interface State {
  language: string
  changeLanguage: () => void
}
export default class Translate extends React.Component<Props> {
  public state: State = {
    language: "ja",
    changeLanguage: this.changeLanguage.bind(this),
  }
  private changeLanguage() {
    switch (this.state.language) {
      case "ja":
        this.setState({ language: "en" })
        break
      case "en":
        this.setState({ language: "ja" })
        break
      default:
    }
  }

  render() {
    return (
      <IntlContext.Provider value={this.state}>
        {this.props.children}
      </IntlContext.Provider>
    )
  }
}
