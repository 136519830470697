import * as React from "react"
import styled, { keyframes } from "styled-components"
import Img from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

const Wrap = styled.div`
  margin: 2vh 0 4vh 0;
  height: 52vh;
`
const StyledImg = styled(Img)`
  height: 100%;
  margin: auto;
  max-width: 90%;
  overflow: visible !important;
  div {
    width: 0 !important;
    padding-bottom: 0 !important;
  }
  img {
    bottom: 0;
    box-shadow: 0 0 0.1em 0.03em #101010, 0.6em 0.8em 0.5em rgba(0,0,0,0.3), -0.4em 0.4em 0.2em rgba(0,0,0,0.2), 0.1em 0.3em 0.1em rgba(0,0,0,0.1),0 0 4em rgba(0,0,0,0.1);
    margin: auto;
    right: 0;
    max-height: 100%;
    max-width: 100%;
  }
  animation: ${fadeIn} 1.2s linear;
`

const MainImage = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulGallery(filter: { node_locale: { eq: "ja" } }) {
        edges {
          node {
            id
            image {
              fluid(maxWidth: 1280) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
            creationDate(formatString: "YYYY")
          }
        }
      }
    }
  `)
  const allImages: IMainImage[] = data.allContentfulGallery.edges
  const getYear = (index: number) => {
    var year: number = new Date().getFullYear()
    year = year + index
    var yearString: string = year.toString()
    return yearString
  }
  const thisYear: string = getYear(0)
  const lastYear: string = getYear(-1)

  const filterImages: IMainImage[] = allImages.filter(
    ({ node }) =>
      // 今年と去年分のみ画像にフィルターをかける
      node.creationDate === thisYear || node.creationDate === lastYear
  )
  const randomNum = Math.floor(Math.random() * filterImages.length)
  const mainImageFluid = filterImages[randomNum].node.image.fluid
  return (
    <Wrap>
      <StyledImg
        fluid={mainImageFluid}
        imgStyle={{ height: "auto", width: "auto" }}
        placeholderStyle={{ overflow: "visible" }}
        loading="eager"
      />
    </Wrap>
  )
}
export default MainImage
/** 
const query = graphql`
  query {
    allContentfulGallery(filter: { node_locale: { eq: "ja" } }) {
      edges {
        node {
          id
          image {
            fluid(maxWidth: 1280) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          creationDate(formatString: "YYYY")
        }
      }
    }
  }
`
*/
interface IMainImage {
  node: {
    id: string
    image: {
      fluid: any
    }
    creationDate: string
  }
}
