import * as React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"
import Masonry from "react-masonry-component"
import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css"
import IntlContext from "./context"

const Item = styled.a`
  display: block;
  width: 25%;
`
const StyledImg = styled(Img)`
  margin: 2%;
`
const InfoBlock = styled.div`
  border-bottom: solid 1px #c39747;
  justify-content: space-between;
  display: flex;
  padding: 1vh 0;
`
const SelectYear = styled.div`
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
`
const CreatedYear = styled.a`
  color: ${(props: StyledProps) => (props.active ? "#c39747" : "#ccc")};
  font-size: 1.2em;
  line-height: 2em;
  padding: 0 1vw;
  &:hover {
    cursor: pointer;
  }
`

interface StyledProps {
  active: boolean
}
interface State {
  selectedYear: string
}
const Gallery = () => {
  const selectYear = (select: string) => {
    setSelectedYear(select)
  }

  const data = useStaticQuery(graphql`
    query {
      allContentfulGallery(sort: { fields: creationDate, order: DESC }) {
        edges {
          node {
            id
            title
            description
            image {
              fluid {
                ...GatsbyContentfulFluid_withWebp
              }
              file {
                url
              }
            }
            creationDate(formatString: "YYYY")
            node_locale
          }
        }
      }
    }
  `)
  const images: IGallery[] = data.allContentfulGallery.edges
  // 制作年の重複を削除
  const years = Array.from(new Set(images.map(({ node }) => node.creationDate)))
  // 表示画像の制作年選択
  const [selectedYear, setSelectedYear] = React.useState(years[0])
  return (
    <IntlContext.Consumer>
      {({ language }) => {
        let text: string
        switch (language) {
          case "ja":
            text = "作品"
            break
          case "en":
            text = "ARTWORK"
            break
          default:
        }
        return (
          <>
            <InfoBlock>
              <h1 style={{ width: "6.5em" }}>{text}</h1>
              <SelectYear>
                <CreatedYear
                  onClick={() => selectYear("")}
                  active={selectedYear === ""}
                >
                  ALL
                </CreatedYear>
                {years.map(element => {
                  return (
                    <CreatedYear
                      key={element}
                      onClick={() => selectYear(element)}
                      active={selectedYear === element}
                    >
                      {element}
                    </CreatedYear>
                  )
                })}
              </SelectYear>
            </InfoBlock>
            <Data selectedYear={selectedYear} data={images} lang={language} />
          </>
        )
      }}
    </IntlContext.Consumer>
  )
}
export default Gallery

interface DataProps {
  selectedYear: string
  data: IGallery[]
  lang: string
}
interface DataState {
  isLightBoxOpen: boolean
}
class Data extends React.Component<DataProps> {
  public state: DataState = {
    isLightBoxOpen: false,
  }

  render() {
    const filterImages = this.props.data.filter(({ node }) => {
      if (node.node_locale !== this.props.lang) {
        return false
      }
      // 選択しなかった場合そのままデータを出力、そうでない場合filterをかける
      if (this.props.selectedYear !== "") {
        return node.creationDate === this.props.selectedYear
      } else {
        return true
      }
    })
    return (
      <Masonry
        enableResizableChildren
        options={{ itemSelector: ".gallery-image" }}
      >
        {filterImages.map(({ node }, index, arr) => {
          return (
            <Item key={node.id} className="gallery-image">
              <LightImage imgNode={node} index={index} arr={arr} />
            </Item>
          )
        })}
      </Masonry>
    )
  }
}

const StyledZoomImg = styled.div`
  transition: 0.3s;
  &:hover {
    opacity: 0.8;
    cursor: zoom-in;
  }
`
interface ImageProps {
  imgNode: IImageNode
  index: number
  arr: IGallery[]
}
interface ImageState {
  isOpen: boolean
  index: number
}
// lightbox
class LightImage extends React.Component<ImageProps> {
  public state: ImageState = {
    isOpen: false,
    index: this.props.index,
  }
  render() {
    const index = this.state.index
    return (
      <>
        <StyledZoomImg onClick={() => this.setState({ isOpen: true })}>
          <StyledImg fluid={this.props.imgNode.image.fluid} loading="auto" />
        </StyledZoomImg>
        {this.state.isOpen && (
          <Lightbox
            mainSrc={this.props.arr[index].node.image.fluid.src}
            imageTitle={this.props.arr[index].node.title}
            imageCaption={this.props.arr[index].node.description}
            onCloseRequest={() => this.setState({ isOpen: false })}
            nextSrc={
              this.props.arr[(index + 1) % this.props.arr.length].node.image
                .fluid.src
            }
            prevSrc={
              this.props.arr[
                (index + this.props.arr.length - 1) % this.props.arr.length
              ].node.image.fluid.src
            }
            onMoveNextRequest={() =>
              this.setState({
                index: (index + 1) % this.props.arr.length,
              })
            }
            onMovePrevRequest={() =>
              this.setState({
                index:
                  (index + this.props.arr.length - 1) % this.props.arr.length,
              })
            }
          />
        )}
      </>
    )
  }
}

interface IGallery {
  node: {
    id: string
    title: string
    description: string
    image: {
      fluid: any
      file: {
        url: string
      }
    }
    creationDate: string
    node_locale: string
  }
}

interface IImageNode {
  id: string
  title: string
  description: string
  image: {
    fluid: any
    file: {
      url: string
    }
  }
  creationDate: string
}
